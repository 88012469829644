import React from "react";
import "./carousel.css";

function CarouselItem(props){
    return(
        <div className="item_wrapper" style={{width: props.width }}>
            <div className="image_wrapper">
                <img className="item_image" src={props.image_url} alt="Crediflow partner"/>
            </div>
        </div>
    );
}
export default CarouselItem;



export function CarouselSingleItem(props){
    return(
        <div className="item_wrapper" style={{width: props.width }}>
            <div className="image_wrapper">
                <img className="item_singel_image" src={props.image_url} alt="Crediflow partner"/>
            </div>
        </div>
    );
}